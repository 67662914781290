import React from 'react'
import SearchBar from '../components/SearchBar'
import CategoryCarousel from '../components/CategoryCarousel'
import TopRatedPlaces from '../components/TopRatedPlaces'
import Testimonials from '../components/Testimonials'
import BlogBusiness from '../Blog/Blogbusiness'


const Home = () => {
  return (
    <div>
        {/* <Header/> */}
        <SearchBar/>
        <CategoryCarousel />
        <TopRatedPlaces/>
        <Testimonials/>
        <BlogBusiness/>
        {/* <Footer/> */}
    </div>
  )
}

export default Home