import React from "react";
import './../style/contactus.css'
import { FaShoppingCart, FaUser, FaComments } from "react-icons/fa";
import { useState } from "react";

const ContactUs = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
  };

  return (
    <div className="contact-us">
      {/* Header Section */}
      <div className="contact-header">
        <h1>Contact Us</h1>
        <p>Lists of our all Popular agencies</p>
      </div>

      {/* Contact Options */}
      <div className="contact-options">
        <div className="contact-card">
          <FaShoppingCart className="contact-icon" />
          <h3>Contact Sales</h3>
          <p>sales@drop-your-business.com</p>
          <p>+01 215 245 6258</p>
        </div>

        <div className="contact-card">
          <FaUser className="contact-icon" />
          <h3>Contact Support</h3>
          <p>support@drop-your-business.com</p>
          <p>+01 215 245 6258</p>
        </div>

        <div className="contact-card">
          <FaComments className="contact-icon" />
          <h3>Start Live Chat</h3>
          <p>+01 215 245 6258</p>
          <button className="live-chat-button">Live Chat</button>
        </div>
      </div>

      <div className="contact-form-container">
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Description"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">
          Submit Request
        </button>
      </form>
    </div>


    </div>
  );
};

export default ContactUs;
