import React from "react";
import "./../style/searchbar.css";
import { FaSearch, FaList } from "react-icons/fa";
import { MdLocationSearching } from "react-icons/md";

const SearchBar = () => {
  return (
    <div className="searchbar-main">
        <div className="big-header-capt">
        <h1>Discover Your City</h1></div>
      <div className="search-bar">
        <div className="search-field">
          <FaSearch className="icon" />
          <input type="text" placeholder="Keywords..." />
        </div>
        <div className="location-field">
          <MdLocationSearching className="icon" />
          <input type="text" placeholder="Location..." />
        </div>
        <div className="category-field">
          <FaList className="icon" />
          <select>
            <option>Choose Category</option>
            <option>Hotel & Spa</option>
            <option>Education</option>
            <option>Shopping</option>
            <option>Restaurants</option>
          </select>
        </div>
        <button className="search-button">Search</button>
      </div>
      <ul className="categories-list">
        <li className="category-item">Hotel & Spa</li>
        <li className="category-item">Education</li>
        <li className="category-item">Shopping</li>
        <li className="category-item">Restaurants</li>
      </ul>
    </div>
  );
};

export default SearchBar;
