import React from "react";
import Slider from "react-slick";
import "./../style/testimonial.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: "Shaurya Williams",
      position: "Manager Of Doodle",
      image: "https://themezhub.net/reveal-live/reveal/assets/img/user-3.png", 
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      id: 2,
      name: "Shrithi Sethi",
      position: "CEO Of Applio",
      image: "https://themezhub.net/reveal-live/reveal/assets/img/user-3.png", 
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      id: 3,
      name: "Adam Williams",
      position: "CEO Of Microwoft",
      image: "https://themezhub.net/reveal-live/reveal/assets/img/user-2.png", 
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
    {
      id: 4,
      name: "John Doe",
      position: "CTO Of Example Inc",
      image: "https://themezhub.net/reveal-live/reveal/assets/img/user-2.png", 
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    },
  ];

  const settings = {
    dots: false, 
    infinite: true, 
    speed: 500, 
    slidesToShow: 3, 
    slidesToScroll: 1, 
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonational-main">
      <div className="testimonials-section">
      <h1>Reviews</h1>
        <h2 className="section-title">

          What People <span>Saying</span>
        </h2>
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div className="testimonial-card" key={testimonial.id}>
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-image"
              />
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-position">{testimonial.position}</p>
              <p className="testimonial-feedback">{testimonial.feedback}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
