import React, { useEffect, useState } from "react";
import axios from "axios";
import "./../style/Businesslisting.css";

const BusinessListing = () => {
  const [business, setBusiness] = useState(null); // Store the single business
  const [businessId, setBusinessId] = useState(null); // Store the business ID
  const [error, setError] = useState(null);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false); // Logo upload state
  const [isUploadingBackground, setIsUploadingBackground] = useState(false); // Background upload state

  useEffect(() => {
    // Retrieve and parse the stored object
    const localStorageData = JSON.parse(localStorage.getItem("user"));
    const loginId = localStorageData?.user?.id; // Extract login ID from the stored user object

    const fetchBusiness = async () => {
      if (!loginId) {
        setError("Login ID is missing in local storage.");
        return;
      }

      console.log("Fetching business details...");
      try {
        const response = await axios.get(
          `https://biz.dropyourbusiness.com/api/business/login/${loginId}`
        );
        if (response.data.businesses && response.data.businesses.length > 0) {
          const fetchedBusiness = response.data.businesses[0]; // Get the first business
          setBusiness(fetchedBusiness);
          setBusinessId(fetchedBusiness.id); // Set the business ID
        } else {
          setError("No business found for this login ID.");
        }
      } catch (err) {
        setError("Failed to fetch business details.");
        console.error(err);
      }
    };

    fetchBusiness();
  }, []);

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file || !businessId) return;

    const formData = new FormData();
    formData.append("logo", file);

    try {
      setIsUploadingLogo(true);
      const response = await axios.post(
        `https://biz.dropyourbusiness.com/api/business/${businessId}/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const cleanedLogoUrl = response.data.logo.replace(/\\/g, "");

      // Update the business state with the new logo
      setBusiness((prev) => ({
        ...prev,
        logo: cleanedLogoUrl,
      }));

      setIsUploadingLogo(false);
    } catch (err) {
      console.error("Error uploading logo:", err);
      setIsUploadingLogo(false);
    }
  };

  const handleBackgroundImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file || !businessId) return;

    const formData = new FormData();
    formData.append("images[]", file);

    try {
      setIsUploadingBackground(true);
      const response = await axios.post(
        `https://biz.dropyourbusiness.com/api/business/${businessId}/images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const rawImageUrl = response.data.images[0];
      const cleanedImageUrl = rawImageUrl.replace(/\\/g, "");

      setBusiness((prev) => ({
        ...prev,
        images: [cleanedImageUrl, ...prev.images],
      }));
      setIsUploadingBackground(false);
    } catch (err) {
      console.error("Error uploading background image:", err);
      setIsUploadingBackground(false);
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!business) {
    return <div className="loading">Loading business details...</div>;
  }

  const {
    business_name,
    owner_name,
    category,
    sub_category,
    description,
    
    images,
    address,
    social_accounts,
    business_time,
  } = business;

  const backgroundImage = images.length > 0 ? images[0] : "/fallback-image.png";

  return (
    <div className="business-listing">
      <div
        className="profile-section"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
        }}
      >
        <div className="logo-container">
          <img
            src={business.logo}
            alt={`${business.business_name} Logo`}
            className="profile-logo"
          />
          <label htmlFor="upload-logo" className="upload-icon">
            <span>+</span>
          </label>
          <input
            type="file"
            id="upload-logo"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleLogoUpload} 
          />
          {isUploadingLogo && <p>Uploading logo...</p>}
        </div>

        <div className="homebanner-title">
          <h1>{business_name}</h1>
          <p className="category">
            {category} - {sub_category}
          </p>
          <label htmlFor="upload-background" className="upload-background">
            <span>+</span>
          </label>
          <input
            type="file"
            id="upload-background"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleBackgroundImageUpload}
          />
        </div>
      </div>

      {isUploadingLogo && <p>Uploading logo...</p>}
      {isUploadingBackground && <p>Uploading background image...</p>}

      <div className="description-box">
      <h2>Description</h2>
        <p>{description}</p>
      </div>

      <div className="details-section">
        <div className="contact-box">
          <h2>Contact Information</h2>
          <p>
            <strong>Owner:</strong> {owner_name}
          </p>
          <p>
            <strong>Email:</strong> {address.email}
          </p>
          <p>
            <strong>Phone:</strong> {address.contact_1}
          </p>
          <p>
            <strong>Address:</strong>{" "}
            {`${address.street_address_1}, ${address.city}, ${address.state}, ${address.country}, ${address.pincode}`}
          </p>
        </div>

        <div className="social-box">
          <h2>Social Accounts</h2>
          <ul>
            {social_accounts.facebook_url && (
              <li>
                <a href={social_accounts.facebook_url}>Facebook</a>
              </li>
            )}
            {social_accounts.instagram_url && (
              <li>
                <a href={social_accounts.instagram_url}>Instagram</a>
              </li>
            )}
            {social_accounts.linkedin_url && (
              <li>
                <a href={social_accounts.linkedin_url}>LinkedIn</a>
              </li>
            )}
          </ul>
        </div>

        <div className="business-box">
          <h2>Business Timing</h2>
          {business_time.is_24_7 ? (
            <p>Open 24/7</p>
          ) : (
            <p>Working Hours: {business_time.working_hours}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessListing;
