import React from "react";
// import { Link } from "react-router-dom";

import './../Blog/blog.css'

const BlogBusiness = () => {
  const blogs = [
    {
      ID: 1,
      title: "Why People Choose Listio For Own Properties",
      author: "Shilpa Sheri",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
      feature_images:
        "https://themezhub.net/reveal-live/reveal/assets/img/b-1.jpg", // Replace with actual image URL
      tags: "Health, Business",
      created_at: "2023-11-01",
    },
    {
      ID: 2,
      title: "List Of Benefits And Impressive Listio Services",
      author: "Shaurya",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
      feature_images:
        "https://themezhub.net/reveal-live/reveal/assets/img/b-2.jpg", // Replace with actual image URL
      tags: "Banking, Stylish",
      created_at: "2023-11-02",
    },
    {
      ID: 3,
      title: "What People Says About Listio Properties",
      author: "Admin K.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
      feature_images:
        "https://themezhub.net/reveal-live/reveal/assets/img/b-3.jpg", // Replace with actual image URL
      tags: "Fashion, Wedding",
      created_at: "2023-11-03",
    },
  ];



  return (
    <div className="blog-container">
        <h1>News & Blog</h1>
      <h2 className="latest-articles">Updates From <span>Reveal</span></h2>
      <div className="blogs">
        {blogs.map((blog) => (
          <div key={blog.ID} className="blog-card">
            <div className="blog-thumbnail">
              <img src={blog.feature_images} alt={blog.title} />
            </div>
            <div className="blog-content">
              {/* <p className="author">By {blog.author}</p> */}
             <span className="author">By {blog.author}</span>
                <h3 className="blog-title">{blog.title}</h3>
              
              <p className="summary">{blog.content}</p>

              <div className="blog-meta">
                <span className="tags">
                  {blog.tags.split(", ").map((tag, index) => (
                    <span key={index} className="tag">
                      {tag}
                    </span>
                  ))}
                </span>
              
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogBusiness;
