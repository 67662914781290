import React from 'react';
import Slider from 'react-slick';
import { FaLaptop, FaCalculator, FaHammer, FaBriefcase, FaBook } from 'react-icons/fa';
import './../style/CategoryCarousel.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const CategoryCarousel = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,             
  autoplaySpeed: 1500,
  };

  const items = [
    { icon: <FaLaptop />, title: 'IT & Software', listings: 210 },
    { icon: <FaCalculator />, title: 'Accounting', listings: 122 },
    { icon: <FaHammer />, title: 'Automotives', listings: 155 },
    { icon: <FaBriefcase />, title: 'Business', listings: 300 },
    { icon: <FaBook />, title: 'Education', listings: 80 },
  ];

  return (
    <div className='CategoryCarousel'>
    <div className="slider-container">
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index}>
            <div className="card">
              <div className="card-icon">{item.icon}</div>
              <h3 className="card-title">{item.title}</h3>
              <p className="card-listings">{item.listings} Listings</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default CategoryCarousel;
