import React from "react";
import { GrMapLocation } from "react-icons/gr";
import { TfiUser, TfiShield } from "react-icons/tfi";
import "./../style/howItworks.css";

const HowItWorks = () => {
  const steps = [
    {
      id: 1,
      icon: <GrMapLocation />,
      title: "Find Interesting Place",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have Ipsum available.",
      //   color: "#e6f9f4",
      decorationColor: "#e6f9f4",
    },
    {
      id: 2,
      icon: <TfiUser />,
      title: "Contact A Few Owners",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have Ipsum available.",
      //   color: "#fde9cf",
      decorationColor: "#fde9cf",
    },
    {
      id: 3,
      icon: <TfiShield />,
      title: "Make A Reservation",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have Ipsum available.",
      //   color: "#e5f0ff",
      decorationColor: "#e5f0ff",
    },
  ];

  return (
    <div className="how-it-works">
        <h1>Process</h1>
      <h2>
        How It <span>Works</span>
      </h2>
      <div className="steps-container">
        {steps.map((step, index) => (
             <React.Fragment key={step.id}>
          <div className="step" key={step.id}>
            <div
              className="icon-box"
                style={{ backgroundColor: step.color }}
            >
              {step.icon}
              <div
                className="icon-decoration"
                style={{ backgroundColor: step.decorationColor }}
              ></div>
            </div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
             {/* Arrow Image */}
             {index < steps.length - 1 && (
                <div className="arrow">
                  <img
                    src="/images/arrow.png"
                    alt="Arrow"
                    className="arrow-image"
                  />
                </div>
              )}
            </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
