import React from "react";
import './../style/aboutus.css'
import HowItWorks from "../components/HowItWorks";
import Testimonials from "../components/Testimonials";
const AboutUs = () => {
  return (
    <div>
      {/* Header Section */}
      <div className="about-header">
        <h1>About Us</h1>
        <p>Who we are & our mission</p>
      </div>

      {/* Content Section */}
      <div className="about-content">
        <div className="content-left">
          <img
            src="images/b-1.jpg"
            alt="Healthy Lifestyle"
            className="about-image"
          />
          {/* <button className="play-button">
            <span>&#9658;</span>
          </button> */}
        </div>
        <div className="content-right">
          <h4>MediPure Tips</h4>
          <h2>Good Health Moves Us Forward</h2>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium avoids pleasure itself, because doloremque sunt
            explicabo. Avoids pleasure itself, because it is pleasure, but
            because those who do not know how to pursue pleasure undertake
            laborious tasks.
          </p>
          <button className="read-more-button">Read More</button>
        </div>
      </div>
      <Testimonials/>
      <HowItWorks/>
    </div>
  );
};

export default AboutUs;
