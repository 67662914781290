import React from "react";
import { Link } from "react-router-dom";
import { FaSignInAlt } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import "./../style/header.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="header">
      <div className="header-top">
        <div className="logo">
          <Link to="/">
            <img src="/images/logo1.png" alt="Logo" className="logo-image" />
          </Link>
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a
                href="/"
                onClick={() => {
                  localStorage.clear();
                  console.log("Local storage cleared!");
                }}
              >
                Logout
              </a>
            </li>

            <li 
            // onClick={() => {
            //   const user = localStorage.getItem("user");
            //   if (user) {
            //     navigate("/listings");
            //   } else {
            //     navigate("/login");
            //   }
            // }} style={{cursor:"pointer"}}
            >
             <Link to="/listings">Listing</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact-us">Contacts</Link>
            </li>
          </ul>
        </nav>
        <div className="header-actions">
          <Link to="/login" className="sign-in">
            <FaSignInAlt /> Sign In
          </Link>
          <button
            className="add-listing"
            onClick={() => {
              const user = localStorage.getItem("user");
              if (user) {
                navigate("/add-listing");
              } else {
                navigate("/login");
              }
            }}
          >
            <FiPlusCircle /> Add Listings
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
